import React, {useEffect, useState} from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import logoSender from "../../assets/images/logo.png";

import MediaLayout from "../../components/media-layout"

import instance from "../../components/api/httpclient";
import {navigate, useIntl, Link} from "gatsby-plugin-intl";
import moment from "moment";
import fr from "moment/locale/fr"
import nl from "moment/locale/nl"
import addNotification from 'react-push-notification';

class BodyClass extends React.Component {
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const InboxList = () => {
  const intl = useIntl();
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};
  const [inboxMessages, setInboxMessages] = useState([]);

  useEffect(() => {
    if ( intl.locale === 'fr-BE' || intl.locale=== 'fr-LU') {
      moment.locale('fr');
    } else {
      moment.locale('nl');
    }

    // LOADING USER DATA
    instance.get("/inbox",
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
        }
      })
      .then( (res) => {
        setInboxMessages(res.data.inbox);
        }
      ).catch( (error) => {
        //console.log('error', error);
        navigate('/sessions/logout');
      }
    )
  }, []);

  const markAsRead = (event, inboxMessage) => {
    event.preventDefault();
    if (currentUser && inboxMessage) {
      if (false === inboxMessage.isRead) {
        currentUser.countOfEmailToRead = currentUser.countOfEmailToRead - 1;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
      }
    }
  }


  const getMessages = () => {
    if (inboxMessages && inboxMessages.length > 0) {
      return (
        inboxMessages.map((inboxMessage, index) =>
          (<div className={`inbox-list ${inboxMessage.isRead ? "" : " inbox-notread "}`} key={index} onClick={(event) => markAsRead(event, inboxMessage)}>
            <Link to="/inbox/message" state={{id: inboxMessage.id}} className="d-flex align-items-center">
              <div className="inbox-logo">
                <img src={logoSender} alt="Logo Sender" className="img-fluid" />
              </div>
              <div className="inbox-preview">
                <h3>Nissan Now Team</h3>
                <p className="mb-0">{inboxMessage.subject}</p>
              </div>
              <div className="inbox-date">
                {moment().diff(moment.utc(inboxMessage.creationDate), 'days') >= 14 ? moment.utc(inboxMessage.creationDate).format("DD/MM/YYYY") : moment.utc(inboxMessage.creationDate).local().startOf('seconds').fromNow()}
              </div>
            </Link>
          </div>)))};
  }


  const content = () => {
    return(
      <div className="col-lg-8">

        <div className="white-container">
          <h1 className="text-uppercase with-border-top with-border-bottom fs-20 mb-0">{intl.formatMessage({id:"inbox.title"})}</h1>
          {getMessages()}

        </div>
      </div>
    )
  };

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.inbox.inbox" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={content()} currentPage="inbox" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
};

export default InboxList
